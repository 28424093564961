import React, { useState } from 'react';
import { useAuth } from '../../hooks/useAuth';
import { Container, Header, Image, Form, Divider, Message } from 'semantic-ui-react';
import Logo from '../../img/logo.png';
import { useHistory } from 'react-router-dom';
import './startup.css'



const BetaLandingStartup = () => {
  const [email, setEmail] = useState('');
  const [validEmail, setValidEmail] = useState('');
  const [disabled, setDisabled] = useState();
  const { addEmailToBeta } = useAuth();
  const history = useHistory();

  const handleEmailSubmit = (address, type) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isEmail = re.test(String(address).toLowerCase());
    if (isEmail) {
      addEmailToBeta(address, type)
      setValidEmail('success');
      setDisabled(true);
    } else {
      setValidEmail('issue');
      setDisabled(false);
    }
    
  }

  return (
    <>
      <div className="beta-banner cta" onClick={() => history.push('/')}>
        <Image src={Logo} size='small' />
      </div>
      <div className="beta-body">
        <Header as="h1" className="header cta">
          Reach investors for less than a cup of coffee
        </Header>
        <Header as="h2" className="copy cta">
          Let’s put an end to the ‘its who you know’ world and get you connected to investors that want to see your business grow.
        </Header>
        <Container className="signup-container cta">
        
        <Divider hidden />
        {validEmail === 'success' &&
          <Message positive>
          <Message.Header>Thankyou! We will be in touch</Message.Header>
            <p>
              We will keep you posted on updates and news of Impath
            </p>
          </Message>
        }
        {validEmail === 'issue' &&
          <Message negative>
          <Message.Header>Invalid email</Message.Header>
            <p>
              Sorry! It looks like your email is invalid, please check your email and try again
            </p>
          </Message>
        }
        <Form disabled={disabled} onSubmit={() => handleEmailSubmit(email, 'startup')}>
          <Form.Field>
            <input placeholder='Email' onChange={event => setEmail(event.currentTarget.value)}/>
          </Form.Field>
          <Form.Button disabled={disabled} color="orange" className="ctaButton">I would like to know more</Form.Button>
        </Form>
        </Container>
      </div>
    </>
  )
}

export default BetaLandingStartup;