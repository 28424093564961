import React, {useState, useEffect, useContext, createContext} from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';

const firebaseApp = require("firebase");
// Required for side-effects
require("firebase/firestore");

// init firebase

firebase.initializeApp({
  apiKey: process.env.REACT_APP_FB_API,
  authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FB_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE,
  messengerSenderId: process.env.REACT_APP_FB_SENDER,
  appId: process.env.REACT_APP_FB_APP_ID,
});

const AuthContext = createContext();
var db = firebase.firestore()

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(AuthContext);
};

// Provider hook that creates auth object and handles state
export const AuthProvider = ({ children }) => {

  const addEmailToBeta = async (email, type) => {
    const ref = db.collection("beta").doc("emails");
    const data = {[email]: type};
    await ref.update({
      e: firebase.firestore.FieldValue.arrayUnion(data)
    });
  }

  const values = {
    addEmailToBeta,
  };

  return (
    <AuthContext.Provider value={values}>
      {children}
    </AuthContext.Provider>
  );
};