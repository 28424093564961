import React from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import 'semantic-ui-css/semantic.min.css'
import BetaLandingInvestor from './components/views/BetaLandingInvestor';
import BetaLandingStartup from './components/views/BetaLandingStartup';
import BetaLandingMain from './components/views/BetaLandingMain';

function App() {
  if (true) {
    return (
      <Router>
        <Switch>
          <Route exact path="/">
            <BetaLandingMain />
          </Route>
          <Route path="/investor">
            <BetaLandingInvestor beta/>
          </Route>
          <Route path="/startup">
            <BetaLandingStartup beta/>
          </Route>
        </Switch>
      </Router>
    )
  }
}

export default App;
