import React, {useState} from 'react';
import { Container, Header, Message, Image, Card, Grid, Icon, Form, Divider, Segment} from 'semantic-ui-react';
import Logo from '../../img/logo.png';
import { useAuth } from '../../hooks/useAuth';
import './startup.css'
import InvestorImage from '../../img/betaInvestor.jpg';
import StartupImage from '../../img/betaStartup.jpg';
import { useHistory } from 'react-router-dom';


const BetaLandingMain = () => {
  const [userType, setUserType] = useState('');
  const [email, setEmail] = useState('');
  const [validEmail, setValidEmail] = useState('');
  const [validFooterEmail, setValidFooterEmail] = useState('');
  const [disabled, setDisabled] = useState();
  const { addEmailToBeta } = useAuth();
  const history = useHistory();

  const handleEmailSubmit = (address, type) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isEmail = re.test(String(address).toLowerCase());
    if (isEmail) {
      addEmailToBeta(address, type)
      setValidEmail('success');
      setDisabled(true);
    } else {
      setValidEmail('issue');
      setDisabled(false);
    }
  }
  const handleFooterEmailSubmit = (address, type) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isEmail = re.test(String(address).toLowerCase());
    if (isEmail) {
      addEmailToBeta(address, type)
      setValidFooterEmail('success');
      setDisabled(true);
    } else {
      setValidFooterEmail('issue');
      setDisabled(false);
    }
  }
  return (
    <>
      <div className="beta-banner">
        <Image src={Logo} size='small' />
      </div>
      <div className="beta-body">
        <Header as="h1" className="header">
          Opening the door to entrepreneurs, and the world
        </Header>
        <Container className="main-container">
          <Card className="main-investor">
            <div class="invest" onClick={() => setUserType('investor')}>
              <Icon name="line graph" size="massive" />
              <h2 class="text"  onClick={() => setUserType('investor')}>Are you an investor?</h2>
            </div>
          </Card>
          <Card className="main-startup">
            <div class="start"  onClick={() => setUserType('entrepreneur')}>
              <Icon name="rocket" size="massive" />
              <h2 class="text" onClick={() => setUserType('entrepreneur')}>Are you a startup?</h2>
            </div>
          </Card>
        </Container>
        <Container>
          <Divider hidden />
        {validEmail === 'success' &&
          <Message positive>
          <Message.Header>Thankyou! We will be in touch</Message.Header>
            <p>
              We will keep you posted on updates and news of Impath
            </p>
          </Message>
        }
        {validEmail === 'issue' &&
          <Message negative>
          <Message.Header>Invalid email</Message.Header>
            <p>
              Sorry! It looks like your email is invalid, please check your email and try again
            </p>
          </Message>
        }
        {userType !== '' &&
            <>
            <Header className="email-cta" as="h3">{`Join our waiting list as an ${userType}`}</Header>
            <Form disabled={disabled} onSubmit={() => handleEmailSubmit(email, userType)}>
            <Form.Field>
              <input placeholder='Email' onChange={event => setEmail(event.currentTarget.value)}/>
            </Form.Field>
            <Form.Button disabled={disabled} color="orange" className="ctaButton">Join mailing list!</Form.Button>
            </Form>
            </>
          }
        </Container>
        <Divider hidden />
        <Container className="how-work-container">
        <Header as="h2" block>How does it work?</Header>
        <Divider hidden />
        <div className="hiw-container-invest">
          <Card color="blue">
            <Card.Content header='Signup to impath' />
            <Card.Content description='Join impath as an investor!' />
          </Card>
          <Card color="blue">
            <Card.Content header='Find the right oppurtunity for you' />
            <Card.Content description='Refine your searches to businesses and entrepreneurs that match your values and intent. Save your preferences to save time in the future' />
          </Card>
          <Card color="blue">
            <Card.Content header='Get connected, and we get out of your way!' />
            <Card.Content description='Once you find the perfect startup, we connect you with the entrepreneurs and you can follow your existing process' />
          </Card>
        </div>
        <div className="hiw-container-startup">
          <Card color="red">
            <Card.Content header='Signup to impath' />
            <Card.Content description='Join impath as an entrepreneur!' />
          </Card>
          <Card color="red">
            <Card.Content header='Tell us your story' />
            <Card.Content description='Fill out our questionnaire to let us know where you are in your journey, and what you hope to achieve. Let us know about your business and help is find the right investor for you.' />
          </Card>
          <Card color="red">
            <Card.Content header='Connect with investors around the world!' />
            <Card.Content description='We will review your pitch. Once approved your business will be seen by investors from around the world' />
          </Card>
        </div>
        </Container>
      </div>
      
      <Container className="footer">
      <Divider hidden />
        <Divider hidden />
      {validFooterEmail === 'success' &&
          <Message className="footer-msg" positive>
          <Message.Header>Thankyou! We will be in touch</Message.Header>
            <p>
              We will keep you posted on updates and news of Impath
            </p>
          </Message>
        }
        {validFooterEmail === 'issue' &&
          <Message className="footer-msg" negative>
          <Message.Header>Invalid email</Message.Header>
            <p>
              Sorry! It looks like your email is invalid, please check your email and try again
            </p>
          </Message>
        }
            <>
            <Header className="footer-cta" as="h3">{`Join our waiting list!`}</Header>
            <Form className="footer-form" disabled={disabled} onSubmit={() => handleFooterEmailSubmit(email, 'null')}>
            <Form.Field>
              <input placeholder='Email' onChange={event => setEmail(event.currentTarget.value)}/>
            </Form.Field>
            <Form.Button disabled={disabled} color="orange" className="ctaButton">Join mailing list!</Form.Button>
            </Form>
            </>
      </Container>
    </>
  )
}

export default BetaLandingMain;